import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Avatar, Typography, IconButton } from '@mui/material';
import { PhotoCamera, ArrowBack } from '@mui/icons-material';
import { RadioGroup } from '@mui/material'; // Import the RadioGroup component from the '@mui/material' package
import { FormControlLabel, Radio } from '@mui/material';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { fetchUserByDocumentId, updateUserDocument, uploadImageAndSaveToFirestore } from '../services/userService';
// import { getFcmToken } from '../services/firebaseConfig';

const RegisterScreen = () => {
    const { user, loading } = useAuth();
    const [image, setImage] = useState<File | null>(null);
    const [username, setUsername] = useState('');
    const [bio, setBio] = useState('');
    const [gender, setGender] = useState('');
    const [network, setNetwork] = useState('');
    const [age, setUserAge] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
    const { updateUser } = useAuth();

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setImage(event.target.files[0]);
        }
    };

    const handleSubmit = async () => {
        // Handle form submission

        // await getFcmToken();

        if (image) {
            await uploadImageAndSaveToFirestore(user.uid, image);
        }
        const data = await updateUserDocument(user.uid, {
            username,
            email,
            phoneNumber,
            age,
            bio,
            gender,
            network,
        });
        updateUser(data);
        debugger;
        window.history.back();
    };

    useEffect(() => {
        // const fetchData = async () => {
        //     if(user){
        //         await fetchUserByDocumentId(user.uid);
        //     }
        // };

        // fetchData();
    }, []);


    return (
        <Box sx={styles.container}>
            {/* Header with Image and Back Button */}
            <Box sx={styles.header}>
                <IconButton sx={styles.backButton} onClick={() => window.history.back()}>
                    <ArrowBack />
                </IconButton>
                {image ? (
                    <Avatar src={URL.createObjectURL(image)} sx={styles.avatar} />
                ) : (
                    <Avatar sx={styles.avatar}>
                        <PhotoCamera />
                    </Avatar>
                )}
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={handleImageUpload}
                />
                <label htmlFor="icon-button-file">
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        sx={styles.uploadButton}
                    >
                        <PhotoCamera />
                    </IconButton>
                </label>
            </Box>

            {/* Registration Form */}
            <Box sx={styles.form}>
                <Typography sx={styles.label}>שם מלא</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>גיל</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={age}
                    onChange={(e) => setUserAge(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>אימייל</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>םייסבוק \ אינסטגרם</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    value={network}
                    onChange={(e) => setNetwork(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>מספר טלפון</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>ביו</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                    sx={styles.textField}
                />

                <Typography sx={styles.label}>מין</Typography>
                <RadioGroup
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    sx={styles.textField}
                >
                    <FormControlLabel value="male" control={<Radio />} label="זכר" />
                    <FormControlLabel value="female" control={<Radio />} label="נקבה" />
                </RadioGroup>

                <Button variant="contained" fullWidth sx={styles.submitButton} onClick={handleSubmit}>
                    Register
                </Button>
            </Box>
        </Box>
    );
};

const styles = {
    container: {
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        textAlign: 'center' as 'center',
    },
    header: {
        position: 'relative' as 'relative',
        marginBottom: '20px',
    },
    backButton: {
        position: 'absolute' as 'absolute',
        top: '10px',
        left: '10px',
    },
    avatar: {
        width: '120px',
        height: '120px',
        margin: 'auto',
        borderRadius: '50%',
    },
    uploadButton: {
        position: 'absolute' as 'absolute',
        bottom: '0',
        right: '50%',
        transform: 'translateX(50%)',
    },
    form: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '15px',
    },
    label: {
        textAlign: 'left' as 'left',
        marginLeft: '5px',
        color: '#888',
    },
    textField: {
        borderRadius: '8px',
        backgroundColor: '#f5f5f5',
    },
    submitButton: {
        marginTop: '20px',
        padding: '10px 0',
        backgroundColor: '#FF6F61',
        borderRadius: '8px',
    },
};

export default RegisterScreen;
