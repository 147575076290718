import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext'; // Import the useAuth hook
import { Box, Button, IconButton, Typography } from '@mui/material';
import { sendEmail } from '../services/userService';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../services/firebaseConfig';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ArrowBack } from '@mui/icons-material';

const Header: React.FC = () => {
    const [location, setLocation] = useState<string>('Fetching location...');
    const [city, setCity] = useState<string>('');
    const { user, loading } = useAuth();
    const { logout } = useAuth();



    const handleLogout = async () => {
        try {
            const generateQRCode = httpsCallable(functions, 'generateQRCode');

            const response: any = await generateQRCode({ text: 'https://salonim-29dcf.web.app/events/345234' });

            sendEmail(user.user.uid, response.data.qrCodeDataURL)
            // await logout();
            // console.log('User logged out and cleared.');
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    useEffect(() => {

        // Get the current position of the user
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(
        //         (position) => {
        //             const { latitude, longitude } = position.coords;
        //             setLocation(`Lat: ${latitude.toFixed(2)}, Long: ${longitude.toFixed(2)}`);

        //             // Use reverse geocoding to get the city name
        //             fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCMplCIFs55tQnyFOfBjZnp79r8vDgqbmY`)
        //                 .then((response) => response.json())
        //                 .then((data) => {
        //                     const city = data.results[0].address_components.find((component: any) =>
        //                         component.types.includes('locality')
        //                     );
        //                     setCity(city ? city.long_name : 'Unknown');
        //                 })
        //                 .catch((error) => {
        //                     setCity('Unable to retrieve city');
        //                 });
        //         },
        //         (error) => {
        //             setLocation('Unable to retrieve location');
        //         }
        //     );
        // } else {
        //     setLocation('Geolocation is not supported by this browser.');
        // }
    }, []);

    const requestLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setLocation(`Lat: ${latitude}, Long: ${longitude}`);
                    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                },
                (error) => {
                    console.error('Error getting location:', error);
                    setLocation('Unable to retrieve location');
                },
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                }
            );
        } else {
            setLocation('Geolocation is not supported by this browser.');
        }
    };

    const styles = {
        headerContainer: {
            display: 'flex',
            flexDirection: 'row' as const,
            alignItems: 'center' as const,
            justifyContent: 'space-between' as const,
            padding: '10px',
        },
        locationContainer: {
            display: 'flex',
            flexDirection: 'column' as const,
            alignItems: 'center' as const,
            gap: '5px',
        },
        currentLocationText: {
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 500,
            fontSize: '11px',
            lineHeight: '14px',
            color: '#AAAAAA',
        },
        cityText: {
            fontFamily: 'Plus Jakarta Sans',
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '20px',
            color: '#000000',
        },
        iconButton: {
            display: 'flex',
            flexDirection: 'row' as const,
            justifyContent: 'center' as const,
            alignItems: 'center' as const,
            padding: '10px',
            width: '44px',
            height: '44px',
            border: '1px solid #EEEEEE',
            borderRadius: '50%',
        },
    };

    return (
        <Box sx={styles.headerContainer}>
            {/* Left Icon (Location) */}
            {/* <IconButton sx={styles.iconButton} onClick={requestLocation}>
                <LocationOnIcon sx={{ color: '#5D9BFC' }} />
            </IconButton> */}

            {window.history.length > 1 && (
                <Button
                    sx={{
                        backgroundColor: '#ffffff', // Light blue background
                        backdropFilter: 'blur(2.5px)',
                        borderRadius: '50px',
                        padding: '0px',
                        border: '1px solid #1976d2',
                        minWidth: '44px',
                        height: '44px',
                        color: '#1976d2', // Light blue color
                    }}
                    onClick={() => window.history.back()}
                >
                    <ArrowBack />
                </Button>
            )}

            {/* Location Info */}
            <Box sx={styles.locationContainer}>
                <Typography sx={styles.currentLocationText}>Current Location</Typography>
                <Typography sx={styles.cityText}> {city} </Typography>
            </Box>

            {/* Right Icon (Notifications) */}
            <IconButton sx={styles.iconButton}>
                <NotificationsNoneIcon sx={{ color: '#AAAAAA' }} />
            </IconButton>
        </Box>
    );
};

export default Header;
