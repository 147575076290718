import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface ApproveDialogProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
}

const ApproveDialog: React.FC<ApproveDialogProps> = ({ open, onClose, onApprove }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* Title */}
      <DialogTitle id="alert-dialog-title">
        {/* Optional close button (you can replace it with an 'X' icon if needed) */}
        <Button onClick={onClose} style={{ position: 'absolute', top: 10, right: 10 }}>
          ✕
        </Button>
      </DialogTitle>

      {/* Content */}
      <DialogContent>
        <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
          ההרשמה מותנת באישור המארח במידה ותאושרו תקבלו וואטסאפ עם פרטים נוספים ואפשרות תשלום.
        </DialogContentText>
      </DialogContent>

      {/* Actions */}
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose} color="primary" variant="outlined" style={{ marginRight: 8 }}>
          ביטול
        </Button>
        <Button onClick={onApprove} color="primary" variant="contained">
          אישור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveDialog;
