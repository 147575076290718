import React, { useEffect, useState } from 'react';
import { CalendarToday, LocationOn, Star } from '@mui/icons-material';
import { Box, Typography, Avatar, Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

interface EventListProps {
    events: any[];
    tab: string;
    type: string;
}

interface EventCardProps {
    event: any;
    type: string;
}

const EventCard = ({ event, type }: EventCardProps) => {
    return (
        <Link
            to={`${type === 'admin' ? '/events/managment' : '/events'}/${event.event_id}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
        >
            <Box
                component="div"
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding="10px -1px 10px 10px"
                gap="15px"
                width="100%"
                height="123px"
                borderRadius="15px"
                mb={2}
                style={{ overflow: 'hidden', background: '#F8F8F8' }}
            >
                {/* Event Image */}
                <Avatar
                    variant="rounded"
                    src={event.event_images[0]}
                    alt="Event"
                    style={{
                        width: '100px',
                        height: '100px',
                        margin : '0px 0px 20px 8px',
                        objectFit: 'cover',
                        borderRadius : '20px',
                        left: '10px',
                        top: '11.5px',
                    }}
                />

                {/* Event Details */}
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    padding="0"
                    gap="10px"
                    width="100%"
                    marginLeft="10px"
                    height="100px"
                >
                    {/* Tag */}
                    <Chip
                        label={new Date(event.event_date.seconds * 1000).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                        })}
                        size="small"
                        style={{
                            backgroundColor: 'rgba(93, 155, 252, 0.15)',
                            color: '#5D9BFC',
                            borderRadius: '30px',
                            fontSize: '11px',
                            padding: '8px 10px',
                            height: '20px',
                        }}
                    />

                    {/* Event Name */}
                    <Typography
                        style={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: 700,
                            fontSize: '13px',
                            lineHeight: '16px',
                            color: '#000',
                            width: '177px',
                            height: '26px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {event.event_name}
                    </Typography>

                    {/* Date and Location */}
                    <Box display="flex" flexDirection="row" alignItems="center" gap="10px" height="8px">
                        {/* Date */}
                        {/* <Box display="flex" flexDirection="row" alignItems="center" gap="5px" width="100%" height="8px">
                            <CalendarToday fontSize="small" style={{ color: '#5D9BFC', width: '12px', height: '12px' }} />
                            <Typography noWrap 
                                style={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    lineHeight: '10px',
                                    color: '#5D9BFC',
                                }}
                            >
                               03-04 Mar 2024
                            </Typography>
                        </Box> */}
                        {/* Location */}
                        <Box display="flex" flexDirection="row" alignItems="center"  width="100%">
                            <LocationOn fontSize="small" style={{ color: '#5D9BFC', width: '14px', height: '14px' }} />
                            <Typography noWrap
                                style={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    lineHeight: '10px',
                                    color: '#5D9BFC',
                                }}
                            >
                                {event.locationName}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Attendees and Action */}
                    <Box display="flex" justifyContent="flex-start" flexDirection="row" alignItems="center" gap="30px" width="100%" height="25px">
                        {/* Attendees */}
                        <Box display="flex" flexDirection="row" alignItems="center" gap="5px" width="100%">
                            {/* Replace with dynamic avatars */}
                            {event.guests.slice(0, 3).map((guest: { image : string, avatar: string | undefined; }, index: any) => (
                                <Avatar
                                    key={index}
                                    src={guest.image}
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        border: '0.75px solid #F8F8F8',
                                        marginLeft: index > 0 ? '-5px' : '0',
                                    }}
                                />
                            ))}
                            {event.guests.length > 3 && (
                                <Avatar
                                    style={{
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: '#E1EAF9',
                                        border: '0.75px solid #F8F8F8',
                                        fontSize: '3.75px',
                                        color: '#5D9BFC',
                                    }}
                                >
                                    +{event.guests.length - 3}
                                </Avatar>
                            )}
                            <Typography
                                style={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: 400,
                                    fontSize: '8px',
                                    lineHeight: '10px',
                                    color: '#AAAAAA',
                                }}
                            >
                                Member Joined
                            </Typography>
                        </Box>

                        {/* "Interested" Button */}
                        <Button
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                width: '85px',
                                height: '25px',
                                backgroundColor: '#5D9BFC',
                                borderRadius: '30px',
                                minWidth: '0px',
                                margin : '10px'
                            }}
                        >
                            <Star style={{ width: '8.42px', height: '8px', color: '#FFFFFF' }} />
                            <span
                                style={{
                                    width: '51px',
                                    height: '7px',
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '10px',
                                    lineHeight: '13px',
                                    color: '#FFFFFF',
                                }}
                            >
                                VIEW
                            </span>
                        </Button>

                    </Box>
                </Box>
            </Box>
        </Link>
    );
};

// Helper component for displaying avatars
const AvatarGroup = ({ avatars, max }: { avatars: any[]; max: number }) => {
    const displayedAvatars = avatars.slice(0, max);
    return (
        <Box display="flex" width={'100%'} alignItems="center">
            {displayedAvatars.map((guest, index) => (
                <Avatar
                    key={index}
                    src={guest.avatar}
                    style={{
                        width: '24px',
                        height: '24px',
                        // marginLeft: index === 0 ? 0 : '-8px',
                        border: '2px solid #fff',
                    }}
                />
            ))}
            {avatars.length > max && (
                <Avatar
                    style={{
                        width: '24px',
                        height: '24px',
                        marginLeft: '-8px',
                        backgroundColor: '#e0e0e0',
                        fontSize: '12px',
                    }}
                >
                    +{avatars.length - max}
                </Avatar>
            )}
        </Box>
    );
};

// Main list component
const EventList = ({ events, tab, type }: EventListProps) => {
    const { user } = useAuth();
    const [updatedEvents, setUpdatedEvents] = useState(events);

    useEffect(() => {
        if (user?.events) {
            const fetchEventStatus = async () => {
                const updatedEvents = events.map((event) => {
                    const matchingEvent = user.events.find(
                        (userEvent: { event_id: any }) => userEvent.event_id === event.event_id
                    );
                    if (matchingEvent) {
                        return {
                            ...event,
                            eventStatus: matchingEvent.status,
                        };
                    }
                    return event;
                });
                setUpdatedEvents(updatedEvents); // Update updatedEvents with the updated events
            };
            fetchEventStatus();
        } else {
            setUpdatedEvents(events); // Update updatedEvents with the updated events
        }
    }, [events]);

    return (
        <Box>
            {updatedEvents?.map((event, index) => (
                <EventCard key={index} event={event} type={type} />
            ))}
        </Box>
    );
};

export default EventList;
