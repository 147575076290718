import React, { useState } from 'react';
import { Box, Typography, Avatar, IconButton, Accordion, AccordionSummary, AccordionDetails, Switch, TextField, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Icon for expanding the accordion
import { useAuth } from '../context/AuthContext';
import { PhotoCamera } from '@mui/icons-material';
import { updateUserDocument, uploadImageAndSaveToFirestore } from '../services/userService';

const Profile = () => {
  const { user } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [username, setUsername] = useState(user?.username || '');
  const [role, setRole] = useState(user?.occupation || '');
  const [image, setImage] = useState<File | null>(null);
  const [email, setEmail] = useState('');
  const [lectureLink, setLectureLink] = useState('');

  // Handle the profile picture change
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Toggle edit mode and handle form submission
  const toggleEditMode = async () => {
    if (editMode) {
      // When leaving edit mode, submit changes (e.g., update the user profile)
      await updateUserProfile(); // Update the user profile before exiting edit mode
    }
    setEditMode(!editMode); // Toggle edit mode
  };

  const liverRequest = async () => {

  };

  const updateUserProfile = async () => {
    try {
      const updatedUserData = { username, email };
      if (image) {
        // Upload the image using UserService or another service
        const imageUrl = await uploadImageAndSaveToFirestore(user.user.uid, image);
        // updatedUserData.image = imageUrl; // Assign new image URL if image is uploaded
      }

      // Call UserService to update the user details

      await updateUserDocument(user.user.uid, updatedUserData);
      console.log('User profile updated:', updatedUserData);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  return (
    <Box sx={styles.container}>
      {/* Profile Section */}
      <Box sx={styles.profileSection}>
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={image ? URL.createObjectURL(image) : ''}
            // src={image ? URL.createObjectURL(image) : user.image}
            sx={styles.avatar}
          />
          <IconButton sx={styles.editIcon} component="label">
            <input hidden accept="image/*" type="file" onChange={handleImageChange} />
            <PhotoCamera />
          </IconButton>
        </Box>
        <Typography sx={styles.name}>{username}</Typography>
        <Typography sx={styles.role}>{role}</Typography>
      </Box>

      {/* Stats Section */}
      {user && user.events && (
        <Box sx={styles.statsSection}>
          <Box sx={styles.stat}>
            <Typography sx={styles.statNumber}>{user.events.length}</Typography>
            <Typography sx={styles.statLabel}>Events</Typography>
          </Box>
          <Box sx={styles.stat}>
            <Typography sx={styles.statNumber}>0</Typography>
            <Typography sx={styles.statLabel}>Reviews</Typography>
          </Box>
          <Box sx={styles.stat}>
            <Typography sx={styles.statNumber}>0</Typography>
            <Typography sx={styles.statLabel}>Following</Typography>
          </Box>
        </Box>
      )}

      {/* Account Section as Accordion */}
      <Accordion sx={styles.accountSection}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="account-content"
          id="account-header"
          sx={styles.accountSummary}
        >
          <Typography sx={styles.accountTitle}>Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.tabContent}>
            {/* Profile Image */}
            <Box sx={{ position: 'relative', textAlign: 'center' }}>
              {editMode && (
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={styles.uploadButton}
                >
                  <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                  <PhotoCamera />
                </IconButton>
              )}
            </Box>

            {/* Editable Fields */}
            <Typography sx={styles.label}>שם משתמש</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={styles.textField}
              disabled={!editMode}
            />

            <Typography sx={styles.label}>אימייל</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={styles.textField}
              disabled={!editMode}
            />

            <Typography sx={styles.label}>מחיר</Typography>
            <TextField
              variant="outlined"
              fullWidth
              value="220$/Event"
              sx={styles.textField}
              disabled
            />

            <Button variant="contained" sx={styles.editButton} onClick={toggleEditMode}>
              {editMode ? 'סיום' : 'עריכת פרופיל'}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={styles.accountSection}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="account-content"
          id="account-header"
          sx={styles.accountSummary}
        >
          <Typography sx={styles.accountTitle}>Liver</Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Typography variant="h5" sx={styles.title}>
            האם אתה מעוניין להיות לייבר?
          </Typography>
          <Typography variant="body1" sx={styles.subTitle}>
            מחפשים אנשים שרוצים להציג סיפור אישי...משהו
          </Typography>
          <Typography variant="body1" sx={styles.formLabel}>
            הירשם כאן:
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="על מה ההרצאה"
            sx={styles.textField}
          />
          <Typography sx={styles.label}>לינק לסרטון</Typography>
          <TextField
            variant="outlined"
            fullWidth
            type="text"
            value={lectureLink}
            onChange={(e) => setLectureLink(e.target.value)}
            sx={styles.textField}
          />
          <Button variant="contained" sx={styles.editButton} onClick={liverRequest}>
            הגש בקשה
          </Button>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={styles.accountSection}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="account-content"
          id="account-header"
          sx={styles.accountSummary}
        >
          <Typography sx={styles.accountTitle}>Salon</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={styles.tabContent}>
            <Typography variant="h5" sx={styles.title}>
              אנחנו כל הזמן מחפשים סלונים מעניינים...
            </Typography>
            <Typography variant="body1" sx={styles.subTitle}>
              אנשים מעניינים שיפתחו את הבית שלהם למען המטרה החברתית להכיר אנשים ולהתחבר יותר.
            </Typography>
            <Typography variant="body1" sx={styles.formLabel}>
              הירשם כאן:
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="קצת על עצמך"
              multiline
              rows={4}
              sx={styles.textField}
            />
            <Typography variant="body1" sx={styles.formLabel}>
              מיקום הסלון:
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="מיקום הסלון"
              sx={styles.textField}
            />
            <Button variant="contained" sx={styles.editButton} onClick={liverRequest}>
              הגש בקשה
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

// Styles for the component
const styles = {
  container: {
    maxWidth: '600px',
    margin: 'auto',
    padding: '20px 20px 70px 20px',
  },
  profileSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',
  },
  avatar: {
    width: '120px',
    height: '120px',
  },
  editIcon: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    backgroundColor: '#5D9BFC',
    color: '#fff',
  },
  label: {
    marginBottom: '8px',
    fontWeight: 'bold',
    color: '#333',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '10px',
  },
  role: {
    fontSize: '14px',
    color: '#AAAAAA',
  },
  statsSection: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
    padding: '10px',
    background: '#F8F8F8',
    borderRadius: '15px',
  },
  stat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  statNumber: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: '#5D9BFC',
  },
  statLabel: {
    fontSize: '12px',
    color: '#AAAAAA',
  },
  accountSection: {
    background: '#F8F8F8',
    borderRadius: '15px',
    marginBottom: '20px',
  },
  accountSummary: {
    padding: '10px',
  },
  accountTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  accountItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0',
    borderBottom: '1px solid #EEEEEE',
  },
  accountText: {
    fontSize: '14px',
    fontWeight: '500',
  },
  title: {
    textAlign: 'center',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  subTitle: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#555',
  },
  formLabel: {
    marginBottom: '8px',
    color: '#333',
  },
  editButton: {
    marginTop: '20px',
    backgroundColor: '#1976d2',
    color: '#fff',
  },
  textField: {
    marginBottom: '16px',
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
  },
  tabContent: {
    paddingTop: '20px',
    direction: 'rtl',
  },
  uploadButton: {
    position: 'absolute',
    top: '80%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

export default Profile;


