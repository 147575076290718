import { useAuth } from '../context/AuthContext';

const WhatsAppButton = () => {
    const { user } = useAuth();
    const openWhatsApp = () => {
        const phoneNumber = '+972515885760'; // Recipient's phone number (in international format without + or 00)
        const message = encodeURIComponent('Hello, I would like to inquire about your services.'); // Pre-ready message
        const url = `https://wa.me/${phoneNumber}?text=${message}`;

        window.open(url, '_blank'); // Open WhatsApp with the pre-filled message
    };

    return (
        <button onClick={openWhatsApp} style={{ padding: '10px 20px', backgroundColor: '#25D366', color: '#fff', border: 'none', borderRadius: '5px' }}>
            Open WhatsApp
        </button>
    );
};

export default WhatsAppButton;
