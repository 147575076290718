// src/eventsService.js
import { db } from './firebaseConfig';
import {
    collection,
    addDoc,
    getDocs,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    query,
    where,
    orderBy,
} from 'firebase/firestore';
import { getUserEvents } from './userService';

// Reference to the 'events' collection
const eventsCollection = collection(db, 'Events');
// Create a new event
export const createEvent = async (eventData: any) => {
    try {
        const docRef = await addDoc(eventsCollection, eventData);
        return docRef.id;
    } catch (error) {
        console.error('Error adding event: ', error);
        throw error;
    }
};

export const getEventById = async (id: any) => {
    try {
        // Create a query to find the document by its ID
        const q = query(eventsCollection, where('event_id', '==', id));
        // Get all matching documents
        const querySnapshot = await getDocs(q);

        // Check if we got any results
        if (!querySnapshot.empty) {
            // Assuming each event has a unique ID, get the first matching document
            const eventDoc = querySnapshot.docs[0];
            return { id: eventDoc.id, ...eventDoc.data() };
        } else {
            // If no document matches the ID, return null or handle accordingly
            return null;
        }
    } catch (error) {
        console.error('Error fetching event by ID:', error);
        throw error; // You can also handle this error differently if needed
    }
};

// Approve user for event
export const approveUserForEvent = async (eventId: string, userId: string, status : string) => {
    try {
        const q = query(eventsCollection, where('event_id', '==', eventId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const eventDoc = querySnapshot.docs[0];
            const eventDocRef = doc(db, 'Events', eventDoc.id);
            const eventDocSnap = await getDoc(eventDocRef);

            if (eventDocSnap.exists()) {
                const guests = eventDocSnap.data()?.guests || [];
                const updatedGuests = guests.filter((guest: any) => guest.user_id !== userId);
                const userToApprove = guests.find((guest: any) => guest.user_id === userId);
                if (userToApprove) {
                    userToApprove.status = status;
                    updatedGuests.push(userToApprove);
                }
                await updateDoc(eventDocRef, {
                    guests: updatedGuests
                });
                return true;
            }
            // Rest of the code...
        } else {
            console.error('No event found with the specified event_id');
        }
        console.log('User approved for event successfully');
    } catch (error) {
        console.error('Error approving user for event:', error);
    }
}

// Add user to event
export const addUserToEvent = async (eventId: string, user: any) => {
    try {
        const q = query(eventsCollection, where('event_id', '==', eventId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
            const eventDoc = querySnapshot.docs[0];
            const eventDocRef = doc(db, 'Events', eventDoc.id);

            const eventDocSnap = await getDoc(eventDocRef);
            if (eventDocSnap.exists()) {
                const users = eventDocSnap.data()?.guests || [];
                const userItem = {
                    user_id: user.user.uid,
                    status: 'pending',
                    username: user.username,
                    image: user.image || ''
                };
                users.push(userItem);
                await updateDoc(eventDocRef, {
                    guests: users
                });

                // Rest of the code...
            } else {
                console.error('No event found with the specified event_id');
            }
            console.log('User added to event successfully');
        } else {
            console.error('No such event document!');
        }
    } catch (error) {
        console.error('Error adding user to event:', error);
        throw error;
    }
};

// Read events (fetch all events)
export const getEvents = async (id: any, type: string = '') => {
    try {
        const querySnapshot = await getDocs(eventsCollection);
        const events: {
            [x: string]: any; id: string;
        }[] = [];
        await Promise.all(querySnapshot.docs.map(async (doc) => {
            if (type === 'my') {
                if (id !== '') {
                    const userEvents = await getUserEvents(id); // Await the getUserEvents function call
                    const eventDocData = await doc.data();
                    if (userEvents.map((event: any) => event.event_id).includes(eventDocData.event_id)) {
                        events.push({ id: doc.id, ...eventDocData });
                    }
                }
            } else {
                events.push({ id: doc.id, ...doc.data() });
            }
        }));
        return events;
    } catch (error) {
        console.error('Error fetching events: ', error);
        throw error;
    }
};

// Get events by user ID
export const getEventsByUserId = async (userId: string) => {
    try {
        const q = query(eventsCollection, where('uid', '==', userId));
        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events by user ID:', error);
        throw error;
    }
};

// Read events with conditions (e.g., upcoming or past)
export const getEventsByCondition = async (condition: string) => {
    try {
        let q;
        if (condition === 'upcoming') {
            q = query(
                eventsCollection,
                where('date', '>=', new Date()),
                orderBy('date', 'asc')
            );
        } else if (condition === 'past') {
            q = query(
                eventsCollection,
                where('date', '<', new Date()),
                orderBy('date', 'desc')
            );
        } else {
            q = eventsCollection;
        }

        const querySnapshot = await getDocs(q);
        const events: { id: string; }[] = [];
        querySnapshot.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
        return events;
    } catch (error) {
        console.error('Error fetching events: ', error);
        throw error;
    }
};

// Update an event
export const updateEvent = async (eventId: string, updatedData: any) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await updateDoc(eventDoc, updatedData);
    } catch (error) {
        console.error('Error updating event: ', error);
        throw error;
    }
};

// Delete an event
export const deleteEvent = async (eventId: string) => {
    try {
        const eventDoc = doc(db, 'events', eventId);
        await deleteDoc(eventDoc);
    } catch (error) {
        console.error('Error deleting event: ', error);
        throw error;
    }
};
