import { PhoneAuthProvider } from 'firebase/auth';
import React, { useEffect } from 'react';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { auth } from '../services/firebaseConfig';
import { Box, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { setUserEmptyDoc } from '../services/userService';

const FirebaseAuth = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook
    useEffect(() => {

        auth.settings.appVerificationDisabledForTesting = true; // Disables reCAPTCHA (only for testing)
        auth.useDeviceLanguage();  // Optional: Automatically use the device language for UI

        const uiConfig = {
            signInSuccessUrl: '/profile',
            signInOptions: [
                PhoneAuthProvider.PROVIDER_ID,
            ],
            credentialHelper: firebaseui.auth.CredentialHelper.NONE,
            callbacks: {
                signInSuccessWithAuthResult: (authResult: {
                    user: any; additionalUserInfo: { isNewUser: any };
                }) => {
                    const isNewUser = authResult.additionalUserInfo.isNewUser;
                    
                    if (isNewUser) {
                        setUserEmptyDoc(authResult.user.uid);
                        // Perform actions specific to new users, like setting up a profile
                        navigate('/register');
                    } else {
                        console.log("This is an existing user");
                        navigate('/events');
                        // Perform actions for existing users, like redirecting them to their dashboard
                    }
                    // Return false to prevent automatic redirection
                    return false;
                }
            }
        };

        const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        ui.start('#firebaseui-auth-container', uiConfig);

        return () => ui.reset(); // Clean up FirebaseUI instance on component unmount
    }, []);

    return (
        <div style={{  }}>
            {/* <Box sx={styles.logoSection}>
                <Avatar
                    src={require('../assets/images/salonim.live.jpg')} // Replace with actual logo URL
                    sx={styles.logo}
                    alt="Salonim Logo"
                />
            </Box> */}
            <div id="firebaseui-auth-container"></div>
        </div>
    );
};

const styles = {
    logoSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '20px',
    },
    logo: {
        width: '150px',
        height: '150px',
        marginBottom: '10px',
    }
}

export default FirebaseAuth;