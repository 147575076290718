// About.js
import React from 'react';
import { Box, Typography, Avatar, IconButton, Link } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

// import MatchWrapper from "../components/MatchWrapper";

const About = () => {
  return (
    <Box sx={styles.container}>
      {/* Back Button */}
      <IconButton sx={styles.backButton}>
        <ArrowBack />
      </IconButton>

      {/* Logo and Instagram */}
      <Box sx={styles.logoSection}>
        <Avatar
          src={require('../assets/images/salonim.live.jpg')} // Replace with actual logo URL
          sx={styles.logo}
          alt="Salonim Logo"
        />
        <Box sx={styles.instagramSection}>
          <Avatar
            src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" // Instagram icon URL
            sx={styles.instagramIcon}
            alt="Instagram Icon"
          />
          <Link
            href="https://www.instagram.com/salonim.live/"
            target="_blank"
            underline="none"
            sx={styles.instagramLink}
          >
            https://www.instagram.com/salonim.live/
          </Link>
        </Box>
      </Box>

      {/* Description */}
      <Typography sx={styles.description}>
        סלונים לייב הוא מיזם חברתי שמטרתו להכיר ולחבר בין אנשים מעניינים בסלון הביתי.
      </Typography>
      <Typography sx={styles.team}>
        צוות היוזמים בלה בהל בהל
      </Typography>
    </Box>
  );
};

// Styles for the component
const styles = {
  container: {
    maxWidth: '400px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    textAlign: 'center',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: '10px',
    top: '10px',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '150px',
    height: '150px',
    marginBottom: '10px',
  },
  instagramSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  instagramIcon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  instagramLink: {
    fontSize: '14px',
    color: '#000',
    textDecoration: 'none',
  },
  description: {
    margin: '20px 0',
    fontSize: '16px',
    color: '#333',
  },
  team: {
    fontSize: '14px',
    color: '#666',
    marginTop: '10px',
  },
};

export default About;


// // Sample data for people to swipe
// const peopleData = [
//   { id: 1, name: 'Alice', image: 'https://via.placeholder.com/300x400' },
//   { id: 2, name: 'Bob', image: 'https://via.placeholder.com/300x400' },
//   { id: 3, name: 'Charlie', image: 'https://via.placeholder.com/300x400' },
// ];

// const Match = () => {
//   return (
//     <div>
//       <h1>Tinder Match Demo</h1>
//       <MatchWrapper people={peopleData} />
//     </div>
//   );
// };

// export default Match;
